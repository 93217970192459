import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {AuthService} from "../../api/auth.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {IAuth} from "../../api/Models/iauth";

@Component({
  templateUrl: './start-page.component.html',
  styleUrls: ['./start-page.component.css']
})
export class StartPageComponent implements OnInit {
  constructor(
    private router: Router,
    private authService: AuthService,
  ) {
  }

  ngOnInit() {
    this.authService.isLoggedIn()
      .subscribe(
        res => {
          if (res) {
            this.router.navigate(['/batches', this.authService.currentUser.latestBatchId, 'edit']);
          }
          else {
            this.router.navigate(['/login']);
          }
        });
  }
}
