import {Component, Input, OnInit} from '@angular/core';
import {IBatch} from '../../api/Models/IBatch';
import {ActivatedRoute, Router} from '@angular/router';
import {forkJoin} from 'rxjs/index';
import {BatchesService} from '../../api/batches.service';
import {IStatus} from "../../api/Models/istatus";
import {StatusesService} from "../../api/statuses.service";
import {filterQueryId} from "@angular/core/src/view/util";
import {IInstitute} from "../../api/Models/iinstitute";
import {InstitutesService} from "../../api/institutes.service";
import {consoleTestResultHandler} from "tslint/lib/test";

@Component({
  selector: 'batch-list',
  templateUrl: './batch-list.component.html'
})
export class BatchListComponent implements OnInit {
  @Input() batches: IBatch[];
  @Input() selectedBatchId: number;
  @Input() statuses: IStatus[];

  institutes: IInstitute[];
  private _filterStatus: number;
  private _filterInstitute: number;

  filteredBatches: IBatch[];

  get filterStatus(): number {
    return this._filterStatus;
  }

  set filterStatus(value: number) {
    this._filterStatus = +value;
    this.performFilter();
  }

  get filterInstitute(): number {
    return this._filterInstitute;
  }

  set filterInstitute(value: number) {
    this._filterInstitute = +value;
    this.performFilter();
  }

  constructor(private router: Router,
              private route: ActivatedRoute,
              private statusesService: StatusesService,
              private institutesService: InstitutesService) {
  }


  newBatch(event): void {
    this.router.navigate(['batches', 0], {relativeTo: this.route.parent});
  }

  selectBatch(id, event): void {
    this.router.navigate(['batches', id], {relativeTo: this.route.parent});
  }

  ngOnInit() {
    this.filteredBatches = this.batches;
    this.institutes = this.institutesService.institutes;

    this.loadFilters();
  }

  private loadFilters() {
    this._filterStatus = 0;
    this._filterInstitute = 0;
  }

  private performFilter() {
    this.filteredBatches = this.batches
      .filter((t) => (this.filterStatus === -1 || t.status === this.filterStatus)
        && (this.filterInstitute === 0 || t.instituteId === this.filterInstitute));
  }
}
