import {Component, OnInit} from '@angular/core';
import {ICountry} from '../../api/Models/icountry';
import {INote} from '../../api/Models/inote';
import {StudentsService} from '../../api/students.service';
import {NotesService} from '../../api/notes.service';
import {AuthService} from "../../api/auth.service";

@Component({
  templateUrl: './note-list.component.html'
})
export class NoteListComponent implements OnInit {

  notes: INote[];
  newNote: INote;
  currentUserName: string;

  constructor(
    private notesService: NotesService,
    private authService: AuthService
  ) {
    this.currentUserName = this.authService.currentUser.userName;
  }

  ngOnInit() {
    this.notes = this.notesService.notes;

  }

  public addNote($event): void {
    this.notesService.add(this.newNote).subscribe(
      newBatch => {
        this.newNote = null;
      }
    );
  }

  public openNewNote(): void {
    this.newNote = NotesService.create();
  }

  public get isValid(): boolean {
    return this.newNote.body.length >= 3;
  }
}
