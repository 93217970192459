import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/internal/Observable';
import {IBatch} from './Models/IBatch';
import {EMPTY} from 'rxjs';
import {ApiService} from './api.service';
import {catchError, map} from 'rxjs/operators';
import {of} from 'rxjs/internal/observable/of';
import {IStatus} from './Models/istatus';
import {IInstitute} from './Models/iinstitute';
import {IConfiguration} from "./models/iconfiguration";

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {
  public configuration: IConfiguration;
  private controller: string = 'configuration';

  constructor(private api: ApiService<IConfiguration>) {
    this.configuration = null;
  }


  loadIntoCache(): Observable<any> {
    if (this.configuration !== null) {
      return of([]);
    }

    return this.api.getOne(this.controller).pipe(
      map(item => {
        this.configuration = item;
        return null;
      })
    );
  }
}
