import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/internal/Observable';
import {ApiService} from './api.service';
import {map} from "rxjs/operators";
import {of} from "rxjs/internal/observable/of";
import {NlpMessageService} from "../app/messages/nlp-message.service";
import {INote} from "./Models/inote";
import {ICountry} from "./Models/icountry";

@Injectable({
  providedIn: 'root'
})
export class NotesService{
  private batchId: number;
  public notes: INote[];
  private controller: string = 'notes';

  constructor(private api: ApiService<INote>,
              private messages: NlpMessageService) {
    this.notes = null;
  }

  private setUrl(batchId: number) {
    this.controller  = 'batches/' + batchId + '/notes';
  }

  load(batchId: number): Observable<INote[]> {
    this.batchId = batchId;
    this.setUrl(batchId);


    if (batchId === 0) {
      this.notes = null;
      return of(this.notes);
    }

    return this.api.get(this.controller).pipe(
      map(notes => {
        this.notes = notes;

        if (this.notes.length > 0) {
       //   this.messages.success('Notes have been loaded successfully.');
        }

        return this.notes;
      })
    );
  }

  getCached(id: number): INote {
    return this.notes.find(i => {
        return i.id === id;
      }
    );
  }

  add(note: INote): Observable<INote> {
    return this.api.post(this.controller, note)
      .pipe(
        map(newNote => {
          this.notes.unshift(newNote);
          this.messages.success('Note has been created!');
          return newNote;
        })
      );
  }

  static create(): INote {
    return {
      userName: '',
      createDate: new Date(),
      body: ''
    } as INote;
  }
}
