import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {IStatus} from "../../api/Models/istatus";
import {IBatch} from "../../api/Models/ibatch";
import {IStudent} from "../../api/Models/istudent";
import {ICountry} from "../../api/Models/icountry";
import {CountriesService} from "../../api/countries.service";
import {StudentsService} from "../../api/students.service";
import {BatchesService} from "../../api/batches.service";

@Component({
  templateUrl: './student-list.component.html'
})
export class StudentListComponent implements OnInit {

  students: IStudent[];
  private _filter: string;
  canAddStudent: boolean = false;

  get filter(): string {
    return this._filter;
  }

  set filter(value: string) {
    this._filter = value;
    this.performFilter();
  }


  constructor(private router: Router,
              private studentsService: StudentsService,
              private batchesService: BatchesService,
              private route: ActivatedRoute,
  ) {
  }

  ngOnInit() {
    this.loadFilters();
    this.students = this.studentsService.students;
    const batch = this.batchesService.getCached(this.studentsService.batchId);

    this.canAddStudent = this.batchesService.canSave(batch);
  }

  private loadFilters() {
    this._filter = "";
  }

  private performFilter() {
    this.students = this.studentsService.students
      .filter((t) =>
        this._filter === "" ||
        t.firstName.toLowerCase().indexOf(this._filter.toLowerCase()) > -1 ||
        t.lastName.toLowerCase().indexOf(this._filter.toLowerCase()) > -1
      );
  }

  openDetails(id, $event): void {
    this.router.navigate(['edit', id], {relativeTo: this.route.parent});
    $event.preventDefault();
  }

  newStudent($event): void {
    this.router.navigate(['edit', 0], {relativeTo: this.route.parent});
    $event.preventDefault();
  }
}
