import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/internal/Observable';
import {IBatch} from './Models/IBatch';
import {EMPTY} from 'rxjs';
import {ApiService} from './api.service';
import {catchError, map} from 'rxjs/operators';
import {of} from 'rxjs/internal/observable/of';
import {NlpMessageService} from '../app/messages/nlp-message.service';
import index from '@angular/cli/lib/cli';

@Injectable({
  providedIn: 'root'
})

export class BatchesService {
  public batches: IBatch[];
  private controller: string = 'batches';
  batchId: number;


  constructor(private api: ApiService<IBatch>,
              private messages: NlpMessageService) {
    this.batches = null;
  }

  loadIntoCache(): Observable<IBatch[]> {
    if (this.batches !== null) {
      return of(this.batches);
    }

    return this.api.get(this.controller).pipe(
      map(batches => {
        this.batches = batches;

        if (this.batches.length > 0) {
          // this.messages.success('Batches have been loaded successfully.');
        }

        return this.batches;
      })
    );
  }

  getCached(id: number): IBatch {
    this.batchId = id;

    return this.batches.find(i => {
        return i.id === id;
      }
    );
  }

  add(batch: IBatch): Observable<IBatch> {
    return this.api.post(this.controller, batch)
      .pipe(
        map(newBatch => {
          this.batches.unshift(newBatch);
          this.messages.success('Batch has been created!');
          return newBatch;
        })
      );
  }

  update(batch: IBatch): Observable<IBatch> {
    return this.api.put(this.controller, batch.id, batch)
      .pipe(
        map(batch => {
          this.messages.success('Batch has been Updated!');

          let index = this.batches.indexOf(this.getCached(batch.id));
          this.batches.splice(index, 1, batch);

          return batch;
        })
      );
  }

  delete(batch: IBatch): Observable<string> {
    return this.api.del(this.controller, batch.id).pipe(
      map(result => {

        console.log(result);
        this.messages.success('Batch has been Deleted!');

        let index = this.batches.indexOf(this.getCached(batch.id));
        this.batches.splice(index, 1);

        return '';
      })
    );
  }

  static create(): IBatch {
    return {
      id: 0,
      title: '',
      instituteId: null,
      status: 0,
      trainer1FullName: '',
      trainer2FullName: '',
      trainer3FullName: '',
      trainer1Title: '',
      trainer2Title: '',
      trainer3Title: ''
    } as IBatch;
  }

  public canSave(batch: IBatch): boolean {
    return batch.status === 0 || batch.status === 3;  //initial or returned
  }

}
