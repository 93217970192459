import {Component, OnInit, Output, Input, EventEmitter} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {ILevel} from "../../api/Models/ilevel";
import {LevelsService} from "../../api/levels.service";

@Component({
  selector: 'student-level',
  templateUrl: './student-level.component.html'
})
export class StudentLevelComponent {

  @Input() selectedLevels: number[];
  @Output() toggleLevel: EventEmitter<ILevel> = new EventEmitter();

  constructor(private levelsService: LevelsService) {
  }

  toggle(item: ILevel, $event) {
    this.toggleLevel.emit(item);
  }


  get allLevels(): ILevel[] {
    return this.levelsService.levels;
  }
}
