import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/internal/Observable';
import {IBatch} from './Models/IBatch';
import {EMPTY} from 'rxjs';
import {ApiService} from './api.service';
import {catchError, map} from 'rxjs/operators';
import {of} from 'rxjs/internal/observable/of';
import {IStatus} from './Models/istatus';
import {ICountry} from "./Models/icountry";

@Injectable({
  providedIn: 'root'
})
export class StatusesService{
  public statuses: IStatus[];
  private controller: string = 'statuses';

  constructor(private api: ApiService<IStatus>) {
    this.statuses= null;
  }

  refresh(): Observable<any> {
    this.statuses = null;

    return this.loadIntoCache();
  }

  loadIntoCache(): Observable<any> {

    if (this.statuses !== null)
    {
      return of([]);
    }
    return this.api.get(this.controller).pipe(
      map(statuses => {
        this.statuses = statuses;
        return null;
      })
    );
  }
}
