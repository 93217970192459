import {Pipe, PipeTransform} from '@angular/core';

/*
 * Raise the value exponentially
 * Takes an exponent argument that defaults to 1.
 * Usage:
 *   value | exponentialStrength:exponent
 * Example:
 *   {{ 2 | exponentialStrength:10 }}
 *   formats to: 1024
*/
@Pipe({name: 'nameCutter'})
export class NameCutter implements PipeTransform {
  transform(value: string, exponent: string): string {
    if (value && value.length > 15) {
      return value.substr(0, 15) + '...';
    }

    return value;
  }
}
