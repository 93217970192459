import {BrowserModule} from '@angular/platform-browser';
import {NgModule, NO_ERRORS_SCHEMA} from '@angular/core';

import {AppComponent} from './app.component';
import {NlpPageNotFoundComponent} from './page-not-found/nlp-page-not-found.component';
import {StudentListComponent} from './students/student-list.component';
import {StudentDetailsComponent} from './students/student-details.component';
import {BatchesService} from "../api/batches.service";
import {HttpClientModule} from "@angular/common/http";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {InstitutesService} from "../api/institutes.service";
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {CommonModule} from "@angular/common";
import {NlpMessageModule} from "./messages/nlp-message.module";
import {NlpPageStaticPartsModuleModule} from "./page-static-parts/nlp-page-static-parts.module";
import {NlpRoutingModule} from "./app-routing.module";
import {BatchesComponent} from "./batches/batches.component";
import {BatchDetailsComponent} from "./batches/batch-details.component";
import {BatchListComponent} from "./batches/batch-list.component";
import {NoteListComponent} from "./notes/note-list.component";
import {BatchDetailsTabComponent} from "./batches/batch-details-tab.component";
import {BatchesResolver} from "./batches/batches-resolver.service";
import {ApiService} from "../api/api.service";
import {StudentComponent} from "./students/student.component";
import {StatusesService} from "../api/statuses.service";
import {NameCutter} from "./batches/name-cutter.pipe";
import {StudentsResolver} from "./students/students-resolver.service";
import {StudentsService} from "../api/students.service";
import {CountriesService} from "../api/countries.service";
import {LevelsService} from "../api/levels.service";
import {StudentLevelComponent} from "./students/student-level.component";
import {StudentLevelListComponent} from "./students/student-level-list.component";
import {NotesService} from "../api/notes.service";
import {NotesResolver} from "./notes/notes-resolver.service";
import {AuthService} from "../api/auth.service";
import {AuthGuard} from "../api/auth-guard.service";
import {RouterModule} from "@angular/router";
import {LoginComponent} from "./login/login.component";
import {ConfigurationService} from "../api/configuration.service";
import {LoaderService} from "../api/loader-service";
import {StartPageComponent} from "./start-page/start-page.component";
import {BatchInfoComponent} from "./batches/batch-info.component";
import {StudentInfoComponent} from "./students/student-info.component";

@NgModule({
  declarations: [
    AppComponent,
    StartPageComponent,
    LoginComponent,
    BatchesComponent,
    NlpPageNotFoundComponent,
    BatchDetailsComponent,
    BatchDetailsTabComponent,
    BatchInfoComponent,
    BatchListComponent,
    NoteListComponent,
    StudentComponent,
    StudentListComponent,
    StudentDetailsComponent,
    StudentInfoComponent,
    NameCutter,
    StudentLevelComponent,
    StudentLevelListComponent
  ],
  imports: [
    NlpPageStaticPartsModuleModule,
    NlpMessageModule,
    BrowserModule,
    CommonModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    NlpRoutingModule,
    RouterModule,
    ReactiveFormsModule,
  ],
  providers: [
    AuthService,
    BatchesResolver,
    StudentsResolver,
    AuthGuard,
    NotesResolver,
    ApiService,
    BatchesService,
    InstitutesService,
    StudentsService,
    CountriesService,
    StatusesService,
    NotesService,
    LevelsService,
    ConfigurationService,
    LoaderService
  ],
  bootstrap: [
    AppComponent
  ],
  schemas: [
    NO_ERRORS_SCHEMA
  ]
})

export class AppModule {
}
