import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/internal/Observable';
import {IBatch} from './Models/IBatch';
import {EMPTY} from 'rxjs';
import {ApiService} from './api.service';
import {catchError, map} from 'rxjs/operators';
import {of} from 'rxjs/internal/observable/of';
import {IStatus} from './Models/istatus';
import {IInstitute} from './Models/iinstitute';
import {ICountry} from "./Models/icountry";

@Injectable({
  providedIn: 'root'
})
export class InstitutesService{
  public institutes: IInstitute[];
  private controller: string = 'institutes';

  constructor(private api: ApiService<IInstitute>) {
    this.institutes= null;
  }


  loadIntoCache(): Observable<any> {
    if (this.institutes !== null) {
      return of([]);
    }

    return this.api.get(this.controller).pipe(
      map(items => {
        this.institutes = items;
        return null;
      })
    );
  }
}
