import {NgModule} from '@angular/core';
import {RouterModule} from "@angular/router";
import {BatchesComponent} from "./batches/batches.component";
import {NlpPageNotFoundComponent} from "./page-not-found/nlp-page-not-found.component";
import {BatchDetailsComponent} from "./batches/batch-details.component";
import {StudentListComponent} from "./students/student-list.component";
import {StudentDetailsComponent} from "./students/student-details.component";
import {NoteListComponent} from "./notes/note-list.component";
import {BatchesResolver} from "./batches/batches-resolver.service";
import {StudentComponent} from "./students/student.component";
import {StudentsResolver} from "./students/students-resolver.service";
import {NotesResolver} from "./notes/notes-resolver.service";
import {AuthGuard} from "../api/auth-guard.service";
import {LoginComponent} from "./login/login.component";
import {StartPageComponent} from "./start-page/start-page.component";

@NgModule({
  imports: [
    RouterModule.forRoot([
      {path: '', redirectTo: 'welcome', pathMatch: 'full'},
      {path: 'welcome', component: StartPageComponent},
      {path: 'login', component: LoginComponent},
      {path: 'batches/:id', redirectTo: 'batches/:id/edit', pathMatch: 'full'},
      {
        path: 'batches/:id/edit/edit',
        redirectTo: 'batches/:id/edit',
        pathMatch: 'full'
      },
      {
        path: 'batches/:id/edit',
        component: BatchesComponent,
        resolve: {
          batches: BatchesResolver,
          students: StudentsResolver,
          notes: NotesResolver,
        },
        canActivate: [AuthGuard],
        children: [
          {path: '', redirectTo: 'details', pathMatch: 'full'},
          {path: 'details', component: BatchDetailsComponent},
          {
            path: 'students',
            component: StudentComponent,
            children: [
              {path: '', redirectTo: 'list', pathMatch: 'full'},
              {path: 'list', component: StudentListComponent},
              {path: 'edit/:studentId', component: StudentDetailsComponent}
            ]
          },
          {path: 'notes', component: NoteListComponent}
        ]
      },
      {path: '**', component: NlpPageNotFoundComponent}
    ])
  ],
  exports: [RouterModule]
})

export class NlpRoutingModule {
}
