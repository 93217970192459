import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/internal/Observable';
import {ApiService} from './api.service';
import {map} from "rxjs/operators";
import {of} from "rxjs/internal/observable/of";
import {NlpMessageService} from "../app/messages/nlp-message.service";
import {IStudent} from "./Models/istudent";
import {ICountry} from "./Models/icountry";

@Injectable({
  providedIn: 'root'
})
export class StudentsService {
  public batchId: number;
  public students: IStudent[];
  private controller: string = 'students';

  constructor(private api: ApiService<IStudent>,
              private messages: NlpMessageService) {
    this.students = null;
  }

  private setUrl(batchId: number) {
    this.controller = 'batches/' + batchId + '/students';
  }

  load(batchId: number): Observable<IStudent[]> {
    this.batchId = batchId;
    this.setUrl(batchId);


    if (batchId === 0) {
      this.students = null;
      return of(this.students);
    }

    return this.api.get(this.controller).pipe(
      map(students => {
        this.students = students;

        if (this.students.length > 0) {
          //     this.messages.success('Students have been loaded successfully.');
        }

        return this.students;
      })
    );
  }

  getCached(id: number): IStudent {
    return this.students.find(i => {
        return i.id === id;
      }
    );
  }

  add(student: IStudent): Observable<IStudent> {
    return this.api.post(this.controller, student)
      .pipe(
        map(newStudent => {
          this.students.unshift(newStudent);
          this.messages.success('Student has been created!');
          return newStudent;
        })
      );

  }

  update(student: IStudent): Observable<void> {
    return this.api.put(this.controller, student.id, student)
      .pipe(
        map(res => {
          this.messages.success('Student has been Updated!');

          let index = this.students.indexOf(this.getCached(student.id));
          this.students.splice(index, 1, student);

          return null;
        })
      );
  }

  delete(student: IStudent): Observable<string> {
    return this.api.del(this.controller, student.id).pipe(
      map(result => {

        this.messages.success('Student has been Deleted!');

        let index = this.students.indexOf(this.getCached(student.id));
        this.students.splice(index, 1);

        return "";
      })
    );
  }

  static create(): IStudent {
    return {
      id: 0,
      firstName: '',
      lastName: '',
      address1: '',
      address2: '',
      country: 'United States',
      state: '',
      city: '',
      postalCode: '',
      email: '',
      phone: '',
      levels: []
    } as IStudent;
  }
}
