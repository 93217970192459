import {Injectable, OnInit} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs/internal/Observable';
import {of} from "rxjs/internal/observable/of";
import {ApiService} from './api.service';
import {catchError, map} from "rxjs/operators";
import {NlpMessageService} from "../app/messages/nlp-message.service";
import {IStudent} from "./Models/istudent";
import {User} from "./Models/user";
import {INote} from "./Models/inote";
import {_createDefaultCookieXSRFStrategy} from "@angular/http/src/http_module";
import {Itoken} from "./Models/itoken";
import {IAuth} from "./Models/iauth";
import {IBatch} from "./Models/ibatch";
import {ObservableInput, Observer, throwError} from "rxjs";
import {tsStructureIsReused} from "@angular/compiler-cli/src/transformers/util";
import {LoaderService} from "./loader-service";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  token: string;
  currentUser: User;
  redirectUrl: string;

  constructor(private http: HttpClient,
              private messages: NlpMessageService,
              private loader: LoaderService
  ) {
  }

  isLoggedIn(): Observable<boolean> {
    if (!!this.currentUser) {
      return of(true);
    }
    return this.init();
  }

  login(auth: IAuth): Observable<void> {
    this.loader.show();
    return this.http.post<Itoken>('api/auth/token', auth)
      .pipe(
        map(tokeninfo => {
          localStorage.setItem('id_token', tokeninfo.token);
          this.token = tokeninfo.token;
          this.currentUser = new User(tokeninfo.userFullName, tokeninfo.latestBatchId);
          this.messages.success('User logged in successfully.');
          this.loader.hide();
          return null;
        }), catchError((err) => {
          this.loader.hide();
          return throwError(err);
        })
      );
  }

  logout(): void {
    this.token = null;
    this.currentUser = null;
    localStorage.removeItem("id_token");
  }

  init(): Observable<boolean> {
    const token = localStorage.getItem("id_token");

    if (!token) {
      return of(false);
    }

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + token
    });

    return this.http.get<any>('api/auth/user-info', {headers: headers}).pipe(
      map(res => {
        this.token = token;
        this.currentUser = new User(res.fullName, res.latestBatchId);
        this.messages.success('User refreshed in successfully.');

        return true;
      }), catchError(this.handleError)
    );
  }

  private handleError(err: HttpErrorResponse) {
    localStorage.removeItem("id_token");
    return of(false);
  }
}
