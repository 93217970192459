import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/internal/Observable';
import {IBatch} from './Models/IBatch';
import {EMPTY} from 'rxjs';
import {ApiService} from './api.service';
import {catchError, map} from 'rxjs/operators';
import {of} from 'rxjs/internal/observable/of';
import {IStatus} from './Models/istatus';
import {ILevel} from './Models/ilevel';
import {ICountry} from "./Models/icountry";

@Injectable({
  providedIn: 'root'
})
export class LevelsService {
  public levels: ILevel[];
  private controller: string = 'levels';

  constructor(private api: ApiService<ILevel>) {
    this.levels= null;
  }


  loadIntoCache(): Observable<any> {
    if (this.levels !== null) {
      return of([]);
    }

    return this.api.get(this.controller).pipe(
      map(items => {
        this.levels = items;
        return null;
      })
    );
  }
}
