import {IEntity} from "./ientity";

export class User {
  userName: string;
  latestBatchId: number;

  constructor(userName: string, latestBatchId: number) {
    this.userName = userName;
    if (latestBatchId) {
      this.latestBatchId = latestBatchId;
    }
    else {
      this.latestBatchId = 0;
    }
  }
}
