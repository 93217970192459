import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {IBatch} from "../../api/Models/IBatch";
import {BatchesService} from "../../api/batches.service";
import {ActivatedRoute, Router} from "@angular/router";
import {IInstitute} from "../../api/Models/iinstitute";
import {InstitutesService} from "../../api/institutes.service";
import {Statuses} from "../../../../../../Admin/Admin.UI/client-app/src/api/models/enums/statuses";
import {ConfigurationService} from "../../api/configuration.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {AuthService} from "../../api/auth.service";
import {StudentsService} from "../../api/students.service";
import {IStudent} from "../../api/Models/istudent";

@Component({
  selector: 'student-info',
  templateUrl: './student-info.component.html'
})
export class StudentInfoComponent implements OnInit {
  @Input() student: IStudent;
  @Output() back = new EventEmitter<void>();

  constructor() {
  }

  cancel($event): void {
    this.back.emit();
  }

  ngOnInit() {
  }
}
