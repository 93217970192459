import {Component, Input, OnInit} from '@angular/core';
import {IBatch} from "../../api/Models/IBatch";
import {BatchesService} from "../../api/batches.service";
import {ActivatedRoute, Router} from "@angular/router";
import {IInstitute} from "../../api/Models/iinstitute";
import {InstitutesService} from "../../api/institutes.service";
import {Statuses} from "../../../../../../Admin/Admin.UI/client-app/src/api/models/enums/statuses";
import {ConfigurationService} from "../../api/configuration.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {AuthService} from "../../api/auth.service";
import {StudentsService} from "../../api/students.service";
import {NoSwitchCaseFallThroughWalker} from "tslint/lib/rules/noSwitchCaseFallThroughRule";
import {NlpMessageService} from "../messages/nlp-message.service";
import {StatusesService} from "../../api/statuses.service";

@Component({
  templateUrl: './batch-details.component.html'
})
export class BatchDetailsComponent implements OnInit {

  private batchForm: FormGroup;
  private batch: IBatch;

  private loading: boolean = false;
  private canSend: boolean = false;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private configurationService: ConfigurationService,
              private batchesService: BatchesService,
              private institutesService: InstitutesService,
              private studentsServices: StudentsService,
              private statusServices: StatusesService,
              private messages: NlpMessageService,
              private fb: FormBuilder) {
  }

  get institutes(): IInstitute[] {
    return this.institutesService.institutes;
  }


  ngOnInit() {
    this.batchForm = this.fb.group(
      {
        title: ['', [
          Validators.required,
          Validators.minLength(4)]],
        instituteId: ['', [Validators.required]],
        trainer1FullName: [''],
        trainer2FullName: [''],
        trainer3FullName: [''],
        trainer1Title: [''],
        trainer2Title: [''],
        trainer3Title: ['']
      });

    this.route.parent.params.subscribe(params => {
      if (+params['id'] === 0) {
        this.batch = BatchesService.create();
      }
      else {
        this.batch = this.batchesService.getCached(+params['id']);
      }

      this.loadBatch();

      this.loadPermissions();
    });


  }

  public isAddMode(): boolean {
    return !!this.batch && this.batch.id === 0;
  }


  public canDelete(): boolean {
    return this.batch.id > 0 && this.batch.status === 0; //initial
  }

  public canSave(): boolean {
    return this.batch.status === 0 || this.batch.status === 3;  //initial or returned
  }

  private loadPermissions() {
    this.canSend = this.batchesService.canSave(this.batch) && !!this.studentsServices.students && !!this.studentsServices.students.length;
  }

  public canLoadCertificates(): boolean {
    return this.batch.status === 2;
  }

  public save(): void {
    if (!this.batchForm.valid) {
      return;
    }

    this.loading = true;

    const data = {...this.batch, ...this.batchForm.value};

    if (this.isAddMode()) {
      this.batchesService.add(data).subscribe(
        newBatch => {
          this.router.navigate(['batches', newBatch.id, 'edit', 'students'], {relativeTo: this.route.parent.parent});
          this.loading = false;
        },
        error => {
          this.messages.error('Could not add batch');
          this.loading = false;
        }
      );
    }
    else {
      this.batchesService.update(data)
        .subscribe(
          batch => {
            this.router.navigated = false;
            this.router.navigate(['batches', batch.id], {relativeTo: this.route.parent.parent});
            this.loading = false;
          },
          error => {
            this.messages.error('Could not save batch');
            this.loading = false;
          }
        )
      ;
    }
  }

  public send($event): void {
    //TODO: Update the enum, make sure that save is separated
    this.batch.status = 1;
    this.batchesService.update(this.batch)
      .subscribe(
        batch => {
          this.batch = batch;
          this.statusServices.refresh().subscribe();
          this.router.navigated = false;
          this.router.navigate(['batches', batch.id], {relativeTo: this.route.parent.parent});
        }
      );
  }

  public delete(): void {
    if (!this.batch || this.isAddMode())
      return;

    this.batchesService.delete(this.batch).subscribe(
      result => {
        let batchId = 0;
        if (this.batchesService.batches.length > 0) {
          batchId = this.batchesService.batches[0].id;
        }

        this.router.navigate(['batches', batchId], {relativeTo: this.route.parent.parent});
      },
      error => {
      }
    );
  }

  private loadBatch() {
    this.batchForm.reset();
    this.batchForm.setValue(
      {
        title: this.batch.title,
        instituteId: this.batch.instituteId,
        trainer1FullName: this.batch.trainer1FullName,
        trainer2FullName: this.batch.trainer2FullName,
        trainer3FullName: this.batch.trainer3FullName,
        trainer1Title: this.batch.trainer1Title,
        trainer2Title: this.batch.trainer2Title,
        trainer3Title: this.batch.trainer3Title
      });
  }

  loadCertificates($event): void {
    if (this.batch.status === Statuses.Completed) {
      window.open(this.configurationService.configuration.certificateUrl + '/Reports/' + this.batch.id, '_blank');
    }
  }

}
