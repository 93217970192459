import {Component, Input, OnInit} from '@angular/core';
import {IBatch} from "../../api/Models/IBatch";
import {BatchesService} from "../../api/batches.service";
import {ActivatedRoute, Router} from "@angular/router";
import {IInstitute} from "../../api/Models/iinstitute";
import {InstitutesService} from "../../api/institutes.service";
import {Statuses} from "../../../../../../Admin/Admin.UI/client-app/src/api/models/enums/statuses";
import {ConfigurationService} from "../../api/configuration.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {AuthService} from "../../api/auth.service";
import {StudentsService} from "../../api/students.service";

@Component({
  selector: 'batch-info',
  templateUrl: './batch-info.component.html'
})
export class BatchInfoComponent implements OnInit {

  private institute: string;

  @Input() batch: IBatch;

  constructor(private institutesService: InstitutesService,
              private configurationService: ConfigurationService) {
  }

  ngOnInit() {
    this.institute = this.institutesService.institutes
      .filter((t) => (t.id === this.batch.instituteId))[0].value;
  }

  public canLoadCertificates(): boolean {
    return this.batch.status === 2;
  }

  loadCertificates($event): void {
    if (this.batch.status === Statuses.Completed) {
      window.open(this.configurationService.configuration.certificateUrl + '/Reports/' + this.batch.id, '_blank');
    }
  }
}
