import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {StudentsService} from "../../api/students.service";
import {CountriesService} from "../../api/countries.service";
import {ICountry} from "../../api/Models/icountry";
import {IBatch} from "../../api/Models/ibatch";
import {IStudent} from "../../api/Models/istudent";
import {IInstitute} from "../../api/Models/iinstitute";
import {BatchesService} from "../../api/batches.service";
import {ILevel} from "../../api/Models/ilevel";
import {LevelsService} from "../../api/levels.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {NlpMessageService} from "../messages/nlp-message.service";

@Component({
  templateUrl: './student-details.component.html'
})
export class StudentDetailsComponent implements OnInit {

  private studentForm: FormGroup;
  private student: IStudent;
  private batch: IBatch;

  private loading: boolean = false;

  // Permissions
  saveButtonText: string;
  canSave: boolean = false;

  constructor(private router: Router,
              private studentsService: StudentsService,
              private batchesService: BatchesService,
              private countriesService: CountriesService,
              private route: ActivatedRoute,
              private fb: FormBuilder) {
  }

  ngOnInit() {

    this.studentForm = this.fb.group(
      {
        firstName: ['', [
          Validators.required,
          Validators.minLength(2)]],
        lastName: ['', [
          Validators.required,
          Validators.minLength(2)]],
        address1: [''],
        address2: [''],
        country: [''],
        state: [''],
        city: [''],
        postalCode: [''],
        email: [''],
        phone: ['']
      });

    this.route.params.subscribe(
      params => {
        this.loadStudent(+params['studentId']);
      }
    );
  }

  private loadStudent(studentId: number) {
    this.batch = this.batchesService.getCached(this.studentsService.batchId);

    if (studentId === 0) {
      this.student = StudentsService.create();
      this.saveButtonText = 'Add';
    }
    else {
      this.student = this.studentsService.getCached(studentId);
      this.saveButtonText = 'Edit';
    }

    this.canSave = this.batchesService.canSave(this.batch);

    this.studentForm.reset();
    this.studentForm.setValue(
      {
        firstName: this.student.firstName,
        lastName: this.student.lastName,
        address1: this.student.address1,
        address2: this.student.address2,
        country: this.student.country,
        state: this.student.state,
        city: this.student.city,
        postalCode: this.student.postalCode,
        email: this.student.email,
        phone: this.student.phone
      });
  }

  get countries(): ICountry[] {
    return this.countriesService.countries;
  }

  cancel($event): void {
    this.router.navigate(['list'], {relativeTo: this.route.parent});
  }

  public save($event): void {
    if (!this.studentForm.valid)
      return;

    this.loading = true;

    const data = {...this.student, ...this.studentForm.value};

    if (this.student.id === 0) {
      this.studentsService.add(data).subscribe(
        newBatch => {
          this.router.navigate(['list'], {relativeTo: this.route.parent});
          this.loading = false;
        },
        error => {
          this.loading = false;
        }
      );
    }
    else {
      this.studentsService.update(data)
        .subscribe(
          res => {
            this.router.navigate(['list'], {relativeTo: this.route.parent});
            this.loading = false;
          },
          error => {
            this.loading = false;
          }
        );
    }
  }

  public delete($event): void {
    if (!this.student || this.student.id === 0)
      return;

    this.loading = true;

    this.studentsService.delete(this.student).subscribe(
      result => {
        this.router.navigate(['list'], {relativeTo: this.route.parent});
        this.loading = false;
      },
      error => {
        this.loading = false;
      }

    );
  }

  toggle(item: ILevel, $event) {
    let index = this.student.levels.indexOf(item.id);
    if (index === -1) {
      this.student.levels.splice(0, 0, item.id);
    }
    else {
      this.student.levels.splice(index, 1);
    }

    this.studentForm.markAsDirty();

  }
}

