import {Injectable} from '@angular/core';
import {ToastrService} from "ngx-toastr";

@Injectable()
export class NlpMessageService {
  private _toastr: ToastrService;

  constructor(toastr: ToastrService) {
    this._toastr = toastr;
  }

  success(message: string): void {
    this._toastr.success(message, '');
  }
  error(message: string): void {
    this._toastr.error(message, '' ,{
      positionClass: 'toast-top-full-width',
    });
  }
}
