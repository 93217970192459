import {Component, OnInit} from '@angular/core';
import {BatchesService} from "../../api/batches.service";
import {forkJoin} from 'rxjs';
import {IBatch} from "../../api/Models/IBatch";
import {NlpMessageService} from "../messages/nlp-message.service";
import {ActivatedRoute, Router} from "@angular/router";
import {IStatus} from "../../api/Models/istatus";
import {StatusesService} from "../../api/statuses.service";

@Component({
  templateUrl: './batches.component.html'
})
export class BatchesComponent implements OnInit {
  public batches: IBatch[];
  public statuses: IStatus[];
  private selectedBatchId: number;

  private _batchesApiService: BatchesService;
  private _messages: NlpMessageService;
  private _router: Router;
  private _route: ActivatedRoute;

  constructor(
    batchesApiService: BatchesService,
    private statusService: StatusesService,
    messages: NlpMessageService,
    router: Router,
    route: ActivatedRoute) {
    this._batchesApiService = batchesApiService;
    this._messages = messages;
    this._route = route;
    this._router = router;
  }

  ngOnInit() {
    this._route.params.subscribe(
      params => {
        this.selectedBatchId = +params['id'];
        this.loadAllLists();
      }
    )
  }

  private loadAllLists() {
    this.batches = this._batchesApiService.batches;
    this.statuses = this.statusService.statuses;
    this.selectedBatchId = this._batchesApiService.batchId;
  }
}
