import {Component, OnDestroy, OnInit} from '@angular/core';
import {AuthService} from "../../api/auth.service";
import {Router} from "@angular/router";
import {LoaderService} from "../../api/loader-service";
import {ILoaderState} from "../../api/Models/i-loader-state";
import {Subscription} from "rxjs";

@Component({
  selector: 'nlp-header',
  templateUrl: './nlp-header.component.html',
  styleUrls: ['./nlp-header.component.css']
})
export class NlpHeaderComponent implements OnInit, OnDestroy {
  showLoader: boolean = false;
  private subscription: Subscription;

  constructor(
    private router: Router,
    private authService: AuthService,
    private loaderService: LoaderService) {
  }

  ngOnInit() {
    this.subscription = this.loaderService.loaderState
      .subscribe((state: ILoaderState) => {
        this.showLoader = state.show;
      });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
