import {Component, Input, OnInit} from '@angular/core';
import {IBatch} from "../../api/Models/IBatch";
import {ActivatedRoute, Router} from "@angular/router";
import {NotesService} from "../../api/notes.service";
import {StudentsService} from "../../api/students.service";

@Component({
  selector: 'batch-details-tab',
  templateUrl: './batch-details-tab.component.html'
})
export class BatchDetailsTabComponent implements OnInit {

  selectedBatchId: number;
  studentCount: number;
  noteCount: number;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private studentsServices: StudentsService,
              private notesService: NotesService
  ) {

  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.selectedBatchId = +params['id'];
      this.loadTabCounts();
    });
  }

  get isAddMode(): boolean {
    return this.selectedBatchId === 0;
  }

  selectTab(tab, $event): void {
    if (this.isAddMode)
      $event.preventDefault();
  }

  private loadTabCounts() {
    if (this.isAddMode) {
      this.studentCount = 0;
      this.noteCount = 0;
      return;
    }
    this.studentCount = this.studentsServices.students.length;
    this.noteCount = this.notesService.notes.length;
  }
}
