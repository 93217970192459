import {NgModule} from '@angular/core';
import {NlpHeaderComponent} from "./nlp-header.component";
import {NlpFooterComponent} from "./nlp-footer.component";
import {CommonModule} from "@angular/common";
import {BrowserModule} from "@angular/platform-browser";
import {MatProgressBarModule} from "@angular/material";
import {BreadcrumbsComponent} from "./breadcrumbs.component";

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    MatProgressBarModule
  ],
  declarations: [
    NlpHeaderComponent,
    NlpFooterComponent,
    BreadcrumbsComponent
  ],
  providers: [],
  exports: [
    NlpHeaderComponent,
    NlpFooterComponent,
    BreadcrumbsComponent,
    MatProgressBarModule
  ]
})
export class NlpPageStaticPartsModuleModule {
}
